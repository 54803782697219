html, body {
    margin: 0;
    padding: 0;
    overflow: hidden;
}

.Gravi {
    height: 100vh;
    width: 100vw;
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    overflow: hidden;
}

.header {
    background-color: #ab0034;
    height: 10vh;
    min-height: 40px;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    box-sizing: border-box;
    font-size: 24px;
    font-weight: bold;
    color: #fff;
    padding: 10px;
}

.logo {
    height: 8vh;
    min-height: 40px;
    margin: 0 10px;
    filter: brightness(0) invert(1);
    pointer-events: none;
}

.container {
    min-width: 100vw;
    min-height: 100vh;
    display: block;
}

.container {
    flex-direction: column;
    align-items: center;
    justify-content: left;
    font-size: 16px;
    color: white;
    box-sizing: border-box;
    overflow: hidden;
}

.visualizer {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    min-width: 100vw;
    height: 82vh;
    overflow: hidden;
    overflow-y: visible;
    box-sizing: border-box;
    padding: 10px 0;
}

.question {
    font-size: 16px;
    text-align: right;
    width: calc(100vw - 40px);
    color: #000;
    padding: 20px;
    box-sizing: border-box;
    border-radius: 10px;
    margin: 0 10px;
}

.answer {
    font-size: 16px;
    text-align: left;
    width: calc(100vw - 20px);
    color: #000;
    background-color: #ececed;
    padding: 20px;
    border-radius: 10px;
    box-sizing: border-box;
    margin: 0 10px;
}

.dots {
    height: 40px;
    align-self: flex-start;
    margin-top: 20px;
    display: none;
}

.form {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    min-width: 100vw;
    box-sizing: border-box;
    justify-content: center;
    height: 8vh;
}

.input {
    position: fixed;
    left: 0;
    bottom: 0;
    width: 80vw;
    min-height: 42px;
    box-sizing: border-box;
    font-size: 16px;
    padding: 2.5vh 2vw;
    overflow: hidden; /* Ocultar las barras de desplazamiento */
    resize: none; /* Evitar que el usuario cambie el tamaño manualmente */
}

.submit {
    position: fixed;
    bottom: 0;
    right: 0;
    width: 20vw;
    /*height: 8vh;*/
    margin: 0;
    padding: 10px;
    box-sizing: border-box;
    font-size: 16px;
}

.link {
    color: #61dafb;
}

.option {
    padding: 8px 10px;
    margin: 0 10px 0 0;
    cursor: pointer;
}

.disclaimer_container {
    position: absolute;
    height: 100vh;
    width: 100vw;
    background-color: #000;
    opacity: 0.8;
    z-index: 998;
}

.disclaimer {
    position: absolute;
    margin: 4vh 4vw;
    height: 92vh;
    width: 92vw;
    background-color: #fff;
    box-sizing: border-box;
    padding: 4vh 4vw;
    z-index: 999;
    overflow-y: scroll;
}

.show {
    display: block;
}

.hidden {
    display: none;
}

/* Estilo general de los botones */
button {
    background-color: white; /* Fondo blanco */
    border: 2px solid #ccc; /* Borde gris claro */
    border-radius: 8px; /* Bordes redondeados */
    padding: 10px 20px; /* Espaciado interno */
    font-size: 16px; /* Tamaño de la fuente */
    font-family: 'Arial', sans-serif; /* Tipografía sans-serif */
    color: #333; /* Color del texto */
    cursor: pointer; /* Cambia el cursor al pasar sobre el botón */
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1); /* Sombra suave */
    transition: background-color 0.3s ease, border-color 0.3s ease, box-shadow 0.3s ease; /* Transiciones suaves */
    margin: 10px;
}

/* Estilo de los botones al pasar el cursor sobre ellos */
button:hover {
    background-color: #f0f0f0; /* Fondo gris claro al pasar el cursor */
    border-color: #bbb; /* Cambia el color del borde */
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2); /* Sombra más pronunciada */
}

/* Estilo de los botones al hacer clic */
button:active {
    background-color: #e0e0e0; /* Fondo un poco más oscuro al hacer clic */
    border-color: #aaa; /* Cambia el color del borde */
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1); /* Reduce la sombra */
}

@media (max-width: 366px) {
    .input {
        min-width: 100%;
        bottom: 8vh;
    }

    .submit {
        margin-top: 10px;
        min-width: 100%;
    }
}

@media screen and (max-height: 50px) {
    .visualizer {
        display: none;
    }

    .input {
        display: none;
    }

    .submit {
        display: none;
    }

    .disclaimer {
        display: none;
    }
}